<template><div>

  <VCleaveInput
    v-bind="$attrs"
    hide-details="auto"
    dense
    v-on="$listeners"
    :options="masks[mask]"
  />
  {{ this.value.length }}
  {{ this.phone }}
</div>
</template>

<script>
import VCleaveInput from "vuetify-cleave";

export default {
  components: { VCleaveInput },
  props: {
    value : {},
    mask: {},
  },

  data: () => ({
    masks: {
      cep: {
        delimiters: [".", "-"],
        blocks: [2, 3, 3],
        uppercase: true,
      },
      cnpj: {
        delimiters: [".", ".", "/", "-"],
        blocks: [2, 3, 3, 4, 2],
        uppercase: true,
      },
    },
  }),

  computed : {
  },

  methods: {},
};
</script>