export default
  [
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'authLayout', auth: 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue')
    },
    {
      path: '/login/socialcallback',
      name: 'loginCallback',
      meta : { layout : 'authLayout', auth : 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/LoginSocialCallback.vue')
    },
    {
      path: '/selecionar-empresa',
      name: 'companyAuth',
      meta : { layout : 'authLayout', auth : 'guest' }, // todo this causes loop (needs multiple middlewares)
      component: () => import(/* webpackChunkName: "about" */ '../views/CompanyAuth.vue')
    },
    {
      path: '/recuperar-senha',
      name: 'convite',
      meta : { layout : 'authLayout', auth : 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/RecoverPassword.vue')
    },
    {
      path: '/redefinir-senha/:token',
      name: 'convite',
      meta : { layout : 'authLayout', auth : 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/ResetPassword.vue')
    },
    {
      path: '/convite/:token',
      name: 'convite',
      meta : { layout : 'authLayout', auth : 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/AcceptInvite.vue')
    },
  ]
