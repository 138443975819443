import Vue from 'vue'

export default {

  namespaced: true,

  state: () => ({
    baseSale: {
      operation: 'sale', // todo ??
      customer: null,
      salesman: null, // todo ??
      sale_products: [],
      sale_payments: [],
      general_discount: 0,
      notes: null,
      discount: null,
      account:{}
    },

    sale: {
      operation: 'sale',
      customer: null,
      salesman: null,
      sale_products: [],
      sale_payments: [],
      general_discount: 0,
      notes: null,
      discount: null,
      account:{}
    },

  }),

  getters: {
    hasProducts: state => {
      return state.sale.sale_products.length > 0
    },
    // productsTotal: state => {
    //   return (
    //     Math.round(
    //       state.sale.sale_products.reduce(
    //         (total, row) => total + parseFloat(row.quantity * row.price),
    //         0
    //       ) * 100
    //     ) / 100
    //   );
    // },

    netTotal: state => {
      return (
        Math.round(
          (state.sale.sale_products.reduce(
            (total, row) => total + parseFloat(row.quantity * row.price),
            0
          )) *
          100
        ) / 100
      );
    },

    productDiscountTotal: (state) => {
      return (
        Math.round(
          state.sale.sale_products.reduce(
            (total, row) => total + parseFloat(row.product_discount),
            0
          ) * 100
        ) / 100
      );
    },

    discountTotal: (state, getters) => {
      return Math.round(parseFloat((state.sale.discount / 100) * getters.netTotal))
    },

    discountGeneral: (state, getters) => {
      return state.sale.general_discount = Math.round(parseFloat((getters.productDiscountTotal + getters.discountTotal)))
    },

    // netTotalOnTerms: state => {
    //   return (
    //     Math.round(
    //       (state.sale.sale_products.reduce(
    //         (total, row) => total + parseFloat(row.quantity * row.company_price_payment_on_terms),
    //         0
    //       ) -
    //         state.sale.discount + state.sale.addition + state.sale.shipping) *
    //       100
    //     ) / 100
    //   );
    // },

    // netTotalImmediate: state => {
    //   return (
    //     Math.round(
    //       (state.sale.sale_products.reduce(
    //         (total, row) => total + parseFloat(row.quantity * row.company_price_payment_immediate),
    //         0
    //       ) -
    //         state.sale.discount + state.sale.addition + state.sale.shipping) *
    //       100
    //     ) / 100
    //   );
    // },

    salePaymentsTotal: state => {
      return (
        Math.round(
          state.sale.sale_payments.reduce(
            (total, row) => total + parseFloat(row.value),
            0
          ) * 100
        ) / 100
      );
    },
  },


  mutations: {


    addUnregisteredProduct(state, saleProduct) {
      state.sale.sale_products.push(saleProduct);
    },

    addProduct(state, { product, product_variant, quantity }) {
      // name discount
      let saleProduct = {
        product: product,
        product_variant: product_variant,
        name: product.name,
        price: product_variant.price,
        product_discount: 0,
        quantity: quantity
      };

      state.sale.sale_products.push(saleProduct);
    },

    increaseProduct(state, { product, product_variant }) {
      let productIndex = state.sale.sale_products.findIndex((row) => {
        return row.product.id == product.id && row.product_variant.id == product_variant.id;
      });

      if (productIndex >= 0) {
        state.sale.sale_products[productIndex].quantity = parseFloat(state.sale.sale_products[productIndex].quantity) + 1
      } else {
        this.commit('pdv/addProduct', { product: product, product_variant: product_variant, quantity: 1 })
      }
    },

    removeProduct(state, index) {
      state.sale.sale_products.splice(index, 1);
    },

    removeLastProduct(state) {
      myarray[myarray.length - 1];

      state.sale.sale_products.splice(state.sale.sale_products.length - 1, 1);
    },

    updateProduct(state, { product, index }) {
      let newArr = JSON.parse(JSON.stringify(state.sale.sale_products));

      newArr[index] = product;

      state.sale.sale_products = newArr;
    },

    reset(state) {
      state.id = null
      state.sale = JSON.parse(JSON.stringify(state.baseSale));
    },

    setError(state, error) {
      state.error = error
    },

    addPayment(state, payment) {
      state.sale.sale_payments.push(payment);
    },

    removePayment(state, index) {
      state.sale.sale_payments.splice(index, 1);
    },

    resetPayments(state) {
      state.sale.sale_payments = [];
    },
    addDiscount(state, discount) {
      state.sale.discount = discount;
    },
    addCustomer(state, customer) {
      state.sale.customer = customer;
    }

  },

  actions: {
    // async getSale({ commit }, id) {
    //   const data = await this.dispatch("sale/showSale", id);

    //   commit("setSale", data);
    // },

    // async getConditional({ commit }, id) {
    //   const data = await this.dispatch("conditional/showConditional", id);

    //   commit("setConditional", data);
    // },

    postOrder({ commit, state, getters }) {
      
      state.sale.account = null
      state.sale.operation = 'order'

      return new Promise((resolve, reject) => {
        Vue.$http
          .store("sale/sale", state.sale)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    
    postSale({ commit, state, getters }, account) {
      
      state.sale.account = account
      state.sale.operation = 'sale'

      return new Promise((resolve, reject) => {
        Vue.$http
          .store("sale/sale", state.sale)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    
    // storeSale({ commit, state, getters }, saveAs) {

    //   if (getters.hasPriceOnTerms) {
    //     let saleProducts = JSON.parse(JSON.stringify(state.sale.sale_products));

    //     saleProducts = saleProducts.map((row) => {
    //       row.price = (getters.hasPaymentOnTerms) ? row.company_price_payment_on_terms : row.company_price_payment_immediate
    //       return row
    //     })

    //     commit("setSaleProducts", saleProducts);
    //   }

    //   let url = "sale/sale/" + saveAs;

    //   if (state.id) {
    //     url = url + "/" + state.id;
    //   }

    //   return new Promise((resolve, reject) => {
    //     this.$axios
    //       .$post(url, state.sale)
    //       .then(response => {
    //         this.commit("saleForm/saleStored", response);
    //         resolve(response);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },

    // storeConditional({ commit, state }, saveAs) {
    //   let url = "conditional/emit-conditional";

    //   return new Promise((resolve, reject) => {
    //     this.$axios
    //       .$post(url, state.sale)
    //       .then(response => {
    //         //commit("saleStored");
    //         resolve(response);
    //       })
    //       .catch(error => {
    //         commit("app/setRequestError", error, { root: true });
    //         reject(error);
    //       });
    //   });
    // }
  }
}