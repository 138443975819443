export default {

  namespaced: true,

  state: () => ({

    alert: {
      message: null,
      id: null,
    },
    httpError: null
  }),

  mutations: {
    alert(state, message) {
      state.alert.message = message
      state.alert.id = Math.random()
    },
    setHttpError(state, error) {
      state.httpError = error
    },

    resetHttpError(state) {
      state.httpError = null
    },

  },

  actions: {

  }

}
