
<template>
  <v-main style="height: 100%">
    <v-container class="pa-0" fluid style="height: 100%">
      <div class="d-flex" style="height: 100%">
        <div
          class="d-md-block d-none"
          style="background-color: #65e4ad; width: 60%"
        >
          <v-img
            height="100%"
            src="https://dev.gestaoclick.com.br/wp-content/uploads/background_Programa_Para_Loja_De_Roupas_11_46_08.jpg"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          ></v-img>
        </div>
        <div class="flex-grow-1" style="background-color: #222e3f">
          <v-row class="row justify-center h-100">
            <v-col md="8" style="padding-top: 100px">
              <!-- <app-logo height="60"/> -->
              <slot></slot>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>

import AppLogo from "@/components/app/sections/AppLogo";

export default {
  components : { AppLogo }
};
</script>
