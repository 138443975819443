<template>
  <div>
    <!-- <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar> -->
    <v-app-bar app flat>
      <v-btn @click="$router.push('/vendas')" text icon><v-icon large>mdi-arrow-left</v-icon></v-btn>

      <v-toolbar-title
        ><h3>{{ $route.meta.name }}</h3></v-toolbar-title
      >
    </v-app-bar>
    
    <v-main>
      <v-container class="" fluid>
        <!-- <router-view /> -->
        <slot></slot>
      </v-container>
    </v-main>
    <change-password-form ref="ChangePasswordForm" />
  </div>
</template>

<script>
import ChangePasswordForm from "./../components/auth/forms/ChangePasswordForm";
export default {
  components: {
    ChangePasswordForm,
  },
  data: () => ({
    drawer: true,
    links: [
      {
        route: "/clientes",
        name: "Clientes",
        icon: "mdi-account",
      },
      {
        route: "/produtos",
        name: "Produtos",
        icon: "mdi-dolly",
      },
      {
        route: "/vendas",
        name: "Vendas",
        icon: "mdi-cart",
      },
      {
        route: "/caixa",
        name: "Caixa",
        icon: "mdi-currency-usd",
      },
      {
        route: "/usuarios",
        name: "Usuários",
        icon: "mdi-account-circle-outline",
      },
      {
        route: "/configuracoes",
        name: "Configurações",
        icon: "mdi-cog",
      },
    ],
  }),
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.push("/login");
    },
    changePassword() {
      this.$refs.ChangePasswordForm.open();
    },
  },
};
</script>