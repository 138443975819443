<template>
  <div>
    <!-- <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar> -->
    <v-app-bar app flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title
        ><h3>{{ $route.meta.name }}</h3></v-toolbar-title
      >
    </v-app-bar>
    <v-navigation-drawer
      color="grey lighten-3"
      app
      light
      floating
      v-model="drawer"
    >
      <v-list>
        <v-list-item class="text-center">
          <app-logo
            class="my-4"
            height="60"
          ></app-logo>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-avatar>
            <v-img
              src="https://www.monteirolobato.edu.br/public/assets/admin/images/avatars/avatar1_big.png"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <!-- <v-list-item-title class="text-h6">
              {{ user.name }}</v-list-item-title
            > -->
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="$router.push('selecionar-empresa')">
           <v-list-item-avatar color="grey lighten-1"> 
            <v-icon>mdi-store</v-icon>
          </v-list-item-avatar>
          <!-- <v-list-item-avatar>
            <v-img
              src="https://www.monteirolobato.edu.br/public/assets/admin/images/avatars/avatar1_big.png"
            ></v-img>
          </v-list-item-avatar> -->
          <v-list-item-content>
            <!-- <v-list-item-title class="text-h6">
              {{ company.name }}</v-list-item-title
            > -->
            <!-- <v-list-item-subtitle>{{ company.id }}</v-list-item-subtitle> -->
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>

      <v-list nav dense>
        <v-list-item-group>
          <v-list-item class="my-2">
            <v-btn
              class="text-capitalize"
              color="primary"
              block
              @click="$router.push('/pdv')"
              >Vender</v-btn
            >
          </v-list-item>
          <template v-for="(link, index) in links">
            <v-list-item link :to="link.route" router exact :key="index">
              <v-list-item-action>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item link router exact>
            <v-list-item-action>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ajuda</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container class="" fluid>
        <!-- <router-view /> -->
        <slot></slot>
      </v-container>
    </v-main>
    <change-password-form ref="ChangePasswordForm" />
  </div>
</template>

<script>

import AppLogo from "@/components/app/sections/AppLogo";
import ChangePasswordForm from "./../components/auth/forms/ChangePasswordForm";

export default {
  components: {
    ChangePasswordForm, AppLogo
  },
  data: () => ({
    drawer: true,
    links: [
      {
        route: "/clientes",
        name: "Clientes",
        icon: "mdi-account",
      },
      {
        route: "/produtos",
        name: "Produtos",
        icon: "mdi-dolly",
      },
      {
        route: "/vendas",
        name: "Vendas",
        icon: "mdi-cart",
      },
      {
        route: "/caixa",
        name: "Caixa",
        icon: "mdi-currency-usd",
      },
      {
        route: "/usuarios",
        name: "Usuários",
        icon: "mdi-account-circle-outline",
      },
      {
        route: "/configuracoes",
        name: "Configurações",
        icon: "mdi-cog",
      },
    ],
  }),
  computed: {

    user() {
      return this.$auth.user;
    },

    company() {
      return this.$auth.company;
    },
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.push("/login");
    },
    changePassword() {
      this.$refs.ChangePasswordForm.open();
    },
  },
};
</script>