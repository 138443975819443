<template>
  <v-img v-bind="$attrs" :src="baseUrl + 'logo.png'" contain></v-img>
</template>

<script>
export default {
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style>
</style>