<template>
  <v-snackbar
    :value="isVisible"
    @input="onSnackbarInput($event)"
    bottom
    color="error"
    :timeout="3000"
  >
       <h4 class="d-inline">{{ alert.message }}</h4>
       <template v-slot:action>
        <v-btn class="ml-2" dark text @click="isVisible = false">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    isVisible: false,
  }),

  computed: {
    alert() {
      return this.$store.state.app.alert;
    },
  },

  watch: {
    alert: {
      deep: true,
      handler() {
        this.isVisible = true;
      },
    },
  },

  methods: {
    onSnackbarInput() {
      this.isVisible = false;
    },
  },
};
</script>

<style>
</style>