<template>
  <VCleaveInput
    v-bind="$attrs"
    hide-details="auto"
    dense
    v-on="$listeners"
    :options="options"
    :loading="loading"
  />
</template>

<script>
import VCleaveInput from "vuetify-cleave";

export default {
  components: { VCleaveInput },
  data: () => ({
    loading: false,
    options: {
      delimiters: [".", ".", "-"],
      blocks: [3, 3, 3, 2],
      uppercase: true,
    },
  }),
 };
</script>