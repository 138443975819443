
import Http from './../api/Http.js'
import Vue from 'vue';

const baseURL = (process.env.NODE_ENV === 'production') ? 'https://app.picshop.app/api/' : 'http://localhost:8000/api/'

Vue.use({
  install (Vue) {
    
    let http = new Http({
      baseURL: baseURL
    })

    Vue.prototype.$http = http
    Vue.$http = http;
  }
})
