import Vue from 'vue';

export default async function authMiddleware(to, from, next) {
  if (to.meta.auth == 'guest') {
    next()
    return
  } 
  
  if (!Vue.$auth.isBooted) {
    await Vue.$auth.boot()
  }

  if (!Vue.$auth.isUserLoggedIn()) {
    next('/login')
    return
  } 

  if (!Vue.$auth.isAccountLoggedIn()) {
    next('/selecionar-empresa')
    return
  }

  next()
  return
}