<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <validation-observer #default="{ invalid }" ref="validation">
      <form @submit.prevent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Alterar senha </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    name="Password"
                    rules="required|min:8"
                    #default="{ errors }"
                  >
                    <app-password-field
                      label="Senha atual"
                      v-model="form.oldPassword"
                      :name="'password'"
                      :state="errors.length > 0 ? false : null"
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    name="Password"
                    rules="required|min:8"
                    #default="{ errors }"
                  >
                    <app-password-field
                      label="Nova Senha"
                      v-model="form.newPassword"
                      :name="'password'"
                      :state="errors.length > 0 ? false : null"
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    name="Password"
                    rules="required|min:8"
                    #default="{ errors }"
                  >
                    <app-password-field
                      label="Confirme sua senha"
                      v-model="form.newPassword_confirmation"
                      :name="'password'"
                      :state="errors.length > 0 ? false : null"
                      :error-messages="errors[0]"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions style="background-color: #f5f5f5">
            <v-spacer></v-spacer>
            <v-btn
              @click="validationForm()"
              class="text-capitalize mr-2"
              color="primary"
              >Salvar</v-btn
            >
            <v-btn @click="dialog = false" text class="text-capitalize"
              >Cancelar</v-btn
            >
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    dialog: false,
    form: {},
    baseForm: {
      oldPassword: null,
      newPassword: null,
      newPassword_confirmation: null,
    },
  }),
  methods: {
    async open() {
      await this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    validationForm() {
      this.$refs.validation.validate().then((success) => {
        if (success) {
          this.store();
        }
      });
    },
    store() {
      this.$http
        .store("user/update-password", this.form)
        .then((response) => {
          this.dialog = false;
        })
        .catch((error) => {});
    },
  },
};
</script>
