<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view />
    </component>
    <app-alert />
    <error-handler />
  </v-app>
</template>

<script>

import AppLayout from '@/layouts/AppLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import PosLayout from '@/layouts/PosLayout.vue'
import CustomerAppLayout from '@/layouts/CustomerAppLayout.vue'
import AppAlert from "./components/app/sections/AppAlert.vue";
import ErrorHandler from "./components/app/sections/ErrorHandler.vue";

export default {
  components : { AppLayout, AuthLayout, PosLayout, CustomerAppLayout, ErrorHandler, AppAlert },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap"); */

* {
  /* font-family: "Quicksand", sans-serif !important; */
  /* font-family: "Source Sans Pro", sans-serif; */
  font-family: "Poppins", sans-serif;
}

/* @font-face {
  font-family: "Quicksand", sans-serif !important;
  src: url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap")
    format("opentype");
} */
</style>