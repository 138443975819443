<template>
  <v-select
    v-bind="$attrs"
    hide-details="auto"
    v-on="$listeners"
    dense
  />
</template>

<script>
export default {};
</script>
