import Vue from "vue"

export default {

  namespaced: true,

  state: () => ({

    accounts: [],

    selectedAccount : null

    // openedShift: '',
    // shifts: {},
    // selectedShift: {},
  }),

  mutations: {
    setAccounts(state, accounts) {
      state.accounts = accounts
    },

    setSelectedAccount(state) {
      state.selectedAccount = state.accounts[0]
    }
    // setShifts(state, shifts) {
    //   state.shifts = shifts
    // },
    // setOpenedShift(state, data) {
    //   state.openedShift = data
    // },
    // setSelectedShift(state, selectedShift) {
    //   state.selectedShift = selectedShift
    // },


  },

  getters : {
    lastShift (state, getters, rootState, rootGetters) {
      if (state.selectedAccount) {
        return state.selectedAccount.opened_shift
      }
    },

    isSelectedDrawerOpen (state, getters, rootState, rootGetters) {
      if (getters.lastShift) {
        return (getters.lastShift.status == 'open')
      }

      return false
    },

    selecedDrawerOpenedShift(state, getters, rootState, rootGetters) {
      if (getters.isSelectedDrawerOpen) {
        return getters.lastShift
      }
    },
    isLastShift(state, getters, rootState, rootGetters) {
      if (state.selectedAccount) {
        return state.selectedAccount.last_shift
        // return getters.lastShift
      }
    }
  },

  actions: {

    async accountIndex({ commit }) {
      await Vue.$http.index("account/account").then((response) => {
        commit('setAccounts', response.accounts);

        // provisorio sistema so vai possuir uma conta no inicio
        commit('setSelectedAccount')
      });
    },

    // async getShift({ state, commit }) {
    //   await Vue.$http.show("account/shift", state.account.id).then((response) => {
    //     commit('setOpenedShift', response);
    //     commit('setSelectedShift', response);


    //   });
    // },

    async getShifts({ commit }, searchParams) {
      await Vue.$http.index("account/shift", searchParams).then((response) => {
        commit('setShifts', response);

      });

    },
  }

}
