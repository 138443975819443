import { format, parseISO } from 'date-fns'
import numeral from 'numeral'
import "numeral/locales/pt-br";

export default {

  numeral(value, format = '0 a') {
    if (!value) {
      var value = 0
    }

    numeral.locale('pt-br');

    return numeral(parseFloat(value)).format(format)
  },

  decimal(value, decimalPlaces = 2) {
    if (!value) {
      var value = 0
    }

    numeral.locale('pt-br');

    return numeral(parseFloat(value)).format('0,0.' + "0".repeat(decimalPlaces))

  },


  dateBr(value, dateFormat = 'dd/MM/yyyy') {
    if (value) {
      try {
        return format(parseISO(value), dateFormat)
      } catch (e) {
        return value
      }
    }
  },

  dateTimeBr(value, dateFormat = 'dd/MM/yyyy HH:mm:ss') {
    if (value) {
      try {
        return format(parseISO(value), dateFormat)
      } catch (e) {
        return value
      }
    }
  },

  cpfCnpj() {

  },

  cnpj(value) {
    if (value) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")
    }
    return ''
  },

  cpf(value) {
    if (value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
    }
    return ''
  },

  rg(value) {
    if (value) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, "\$1.\$2.\$3-\$4")
    }
    return ''
  },

  cep(value) {

    if (value) {
      return value.replace(/(\d{5})(\d{3})/g, "\$1-\$2")
    }
    return ''
  },

  phone(value) {

    if (value) {

      if (value.length == 11) {
        return value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, "(\$1) \$2 \$3-\$4")
      }
      if (value.length == 10) {
        return value.replace(/(\d{2})(\d{4})(\d{4})/g, "(\$1) \$2-\$3")
      }

      return ''

    }

    return null
  },



  moneyBr(value) {
    if (!value) {
      var value = 0
    }

    var parsedValue = parseFloat(value)
    var value = parsedValue.toFixed(2).split('.');

    value[0] = value[0].split(/(?=(?:...)*$)/).join('.');

    return 'R$ ' + value.join(',')
    // return value.join(',');
  },

}