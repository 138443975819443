import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import authMiddleware from "./middlewares/authMiddleware"
import auth from './auth'

Vue.use(VueRouter)

const routes = [
  ...auth,
  {
    path: '/',
    name: 'Home',
    meta : { layout : 'appLayout', name : 'POS' },
    component: Home,
  },
 
  {
    path: '/setup',
    name: 'setup',
    meta : { layout : 'authLayout', auth : 'guest' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Setup.vue')
  },
  {
    path: '/clientes',
    name: 'customers',
    meta : { layout : 'appLayout', name : 'Clientes' },
    component: () => import(/* webpackChunkName: "about" */ '../views/CustomersIndex.vue')
  },
  {
    path: '/produtos',
    meta : { layout : 'appLayout', name : 'Produtos' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductsIndex.vue')
  },
  {
    path: '/vendas',
    meta : { layout : 'appLayout', name : 'Vendas' },
    component: () => import(/* webpackChunkName: "about" */ '../views/OrdersIndex.vue')
  },
  {
    path: '/pdv',
    meta : { layout : 'posLayout', name : 'Vender' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Pdv.vue')
  },
  {
    path: '/caixa',
    name: 'caixa',
    meta : { layout : 'appLayout', name : 'Caixa' },
    component: () => import('../views/AccountTransactionsIndex')
  },
 
  {
    path: '/autenticacao_banco_do_brasil',
    name: 'caixa',
    meta : { layout : 'appLayout', name : 'Autenticação Mercado Pago' },
    component: () => import('../views/AuthBancoDoBrasil')
  },
  
  {
    path: '/categorias',
    meta : { layout : 'appLayout', name : 'Categorias' },
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoriesIndex.vue')
  },
  {
    path: '/configuracoes',
    meta: { layout: 'appLayout', name: 'Configurações' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingsListIndex.vue')
  },
  {
    path: '/configuracoes/empresa',
    meta: { layout: 'appLayout', name: 'Dados da Empresa' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingsCompanyIndex.vue')
  },
  {
    path: '/configuracoes/integracao_mercado_pago',
    meta : { layout : 'appLayout', name : 'Integração Mercado Pago' },
    component: () => import('../views/AuthMercadoPago')
  },

  // *** customer app *** //
  // mover para outro frontend
  {
    path: '/customer/order/:id',
    meta : { layout : 'customerAppLayout', auth : 'guest', name : 'Pedido' },
    component: () => import('../views/CustomerApp/OrderView.vue')
  },
  {
    path: '/customer/pagamento/',
    name: 'caixa',
    meta : { layout : 'customerAppLayout', auth : 'guest', name : 'Pagamento' },
    component: () => import('../views/CustomerApp/Checkout')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    meta : { layout : 'appLayout',   name : 'Usuarios' },
    component: () => import('../views/UsersIndex.vue')
  },
 

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  await authMiddleware(to, from, next)

});

export default router