var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"validation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Alterar senha ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-password-field',{attrs:{"label":"Senha atual","name":'password',"state":errors.length > 0 ? false : null,"error-messages":errors[0]},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-password-field',{attrs:{"label":"Nova Senha","name":'password',"state":errors.length > 0 ? false : null,"error-messages":errors[0]},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-password-field',{attrs:{"label":"Confirme sua senha","name":'password',"state":errors.length > 0 ? false : null,"error-messages":errors[0]},model:{value:(_vm.form.newPassword_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "newPassword_confirmation", $$v)},expression:"form.newPassword_confirmation"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticStyle:{"background-color":"#f5f5f5"}},[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v("Salvar")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }