<template>
  <v-main>
    <v-container fluid>
      <slot></slot>
    </v-container>
  </v-main>
</template>

<script>
export default {

};
</script>

<style>
</style>
